
import AuthService from "@/auth/AuthService";
import OJ7060S from "@/service/OJ7060Service";
import ZFunc from  "@/service/ZFunc"
import { Vue } from "vue-class-component";
import zCONST from "@/const";

export default class OJ7060F extends Vue {
    // クラス定義
    AuthS: AuthService = new AuthService();
    OJ7060S: OJ7060S = new OJ7060S();
    ZF: ZFunc = new ZFunc();

    // 検索フィールド変数
    mRefTEKEBU: any = "";       // 定型文

    mUSERKY: any = 0;
    mTOKUKY: any = 0;

    // 一覧フィールド変数
    mDgList: any[] = [];            // データグリッドソース
    loadingFlg: boolean = false;    // ロード中フラグ

    // 変数定義
    $cookies: any;    // cookie

    mounted(): void {
        this.fncInit();
    }

    // 初期表示処理
    fncInit(): void {
        this.fncClear();
        this.mUSERKY = this.$cookies.get("USERKY");

        //ログイン情報取得
        let wParam = { USERKY: this.mUSERKY };
        this.OJ7060S.fncGetUserInfo(wParam).then((data: any) => {
            this.mTOKUKY = data.TOKUKY;
            this.fncGetList();
        });
    }

    // 初期化処理
    fncClear(): void {
        this.mRefTEKEBU = "";
    }

    // 定型文リスト取得処理
    fncGetList(): void {
        let wParam = { 
            TOKUKY: this.mTOKUKY
            ,TEKEBU: this.mRefTEKEBU
        };
        this.loadingFlg = true;
        this.OJ7060S.fncGetList(wParam).then((data: any) => {
            this.mDgList = data.dgList;
        }).finally(() => {this.loadingFlg = false;});
    }

    fncClearButton(): void{
        this.mRefTEKEBU ="";
        this.fncGetList();
    }

    // 詳細表示
    fncShowShosai(pTEKEKY: number): void {
        // 詳細画面を新規モードで呼び出す
        const zZ7061F: any = this.$refs["OJ7061F"];
        let wMODEXX: number;
        if (pTEKEKY == 0) {
            wMODEXX = zCONST.cMODE_ADD;
        } else {
            wMODEXX = zCONST.cMODE_DISP;
        }

        //ダイアログを表示
        zZ7061F.fncShow(
        {
            MODEXX: wMODEXX,
            TEKEKY: pTEKEKY,
        },
        () => {
            //登録成功
            this.fncGetList();
        }
        );
    }
}
